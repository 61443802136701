<template>
    <div>
        <!-- <div class="amenity-card">
            <img class="amenities-img" :src="require('@/assets/icons/' + img )" alt="">
            <h4>{{title}}</h4>
        </div> -->
        <div :class="alt"><img :src="require('@/assets/img/icons/' + img )"
            :alt="alt"><span>{{title}}</span></div>
    </div>
</template>
<script>
export default {
    name:"AmenityCard",
    props:{
        title:{type:String, required:true},
        alt:{type:String},
        img:{type:String, required:true},
    },
    computed:{
       
    }
}
</script>
<style  scoped>
   
</style>