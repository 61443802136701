<template>
    <div>
    
        <section class='property-info-selected'>
        <div class='container-fluid'>
            <div class='property-info-selected-wrapper'>
                <div class='row'>
                    <div class='col-lg-12'>
                        <div class='table-responsive'>
                            <table class='table align-items-center table-flush'>
                                <thead class='thead-light'>
                                    <tr>
                                        <th scope='col'>Property Name</th>
                                        <th scope='col'>Type</th>
                                        <th scope='col'>Location</th>
                                        <th scope='col'>Developer</th>
                                        <th scope='col'>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{selectedProperty.property_name}}</td>
                                        <td>{{selectedProperty.property_type}}</td>
                                        <td>{{selectedProperty.city.name}},{{selectedProperty.state.name}}</td>
                                        <td>{{selectedProperty.client.client_name}}</td>
                                        <td>
                                            {{selectedProperty.property_price | price}}
                                            <a class='table-view-btn'>View</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
export default {
    name:"SelectedProperty",
    props:{
        // property:{type:Object}
    },
    computed:{
          selectedProperty(){
            let s = this. $store.state.properties_module.selectedProperty;
            console.log(s);
           
            return s;
        }
    },
    methods:{
        viewPro(){}
    }
}
</script>
<style  scoped>
    
</style>