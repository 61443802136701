<template>
    <!-- <div p-card-p> -->
        <!-- <div :style="{ backgroundImage: 'url('+property.property_cover_image + ')' }" class="featured_property_img"> -->
            <!-- <div class="card property-card">
                <div class="card-body">
                    <div class="property-image" :style="{ backgroundImage: 'url('+property.property_cover_image + ')' }"  ></div>
                    <div class="property-info">
                        <div class="price-pin">
                            <h3>{{property.property_price | price}}</h3>
                            <p>{{property.city.name}}, {{property.state.name}} Nigeria</p>
                        </div>
                        <div class="property-amenities">
                            <amenity-card :title="property.property_bedrooms+' beds'" img="bedroom.png" />
                            <amenity-card :title="property.property_bathrooms+' bath'" img="bathroom.png" />
                            <amenity-card :title="property.property_size+' sqm'" img="sqm.png" />
                            
                        </div>
                        <div class="property-action">
                            <div class="left">
                            </div>
                            <button class="btn btn-primary action-btn" @click.prevent="chooseProperty">{{btnText}}</button>
                        </div>
                    </div>
                </div>
            </div> -->
    <!-- </div> -->
    <div class="property-cards">
        <div class="property-img" :style="{ backgroundImage: 'url('+property.property_cover_image + ')' }">
          
        </div>
        <div class="property-cards-info">
            <div class="price-location">
                <h4>{{property.property_price | price}}</h4>
                <p><img class="map-icon" src="@/assets/img/icons/location.png"
                        alt="Location">{{property.city.name}}, {{property.state.name}}  Nigeria</p>
            </div>
            <div class="amenities">
                 <amenity-card :title="property.property_bedrooms+' beds'" img="bed.png" alt="bed"/>
                 <amenity-card :title="property.property_bathrooms+' baths'" img="bath.png" alt="bath"/>
                 <amenity-card :title="property.property_size+' sqft'" img="size.png" alt="dimension"/>
                <!-- <div class="bed"><img src="assets/img/icons/bed.png"
                        alt="Beds"><span>4 beds</span></div>
                <div class="bath"><img src="assets/img/icons/bath.png"
                        alt="Baths"><span>4 Baths</span></div>
                <div class="dimension"><img src="assets/img/icons/size.png"
                        alt="sqft"><span>2800 sqft</span></div> -->
            </div>
            <div class="status-cta">
                <div class="status">
                    <h4>Financial Status</h4>
                    <p>Mortgage</p>
                </div>
                <div class="cta"><button @click.prevent="chooseProperty"
                        class="cta-btn" type="button">{{isChoose?'Choose Me':'See Details'}}
                        </button></div>
            </div>
        </div>

       
    </div>
</template>
<script>
import AmenityCard from './AmenityCard'
import {CalculatorMixin} from '@/mixins/CalculatorMixin'
export default {
  components: { AmenityCard },
    name:"PropertyCard",
    mixins:[CalculatorMixin],
    props:{
        btnText:{type:String,default:'See details'},
        property:{type:Object},
        isChoose:{type:Boolean,default:false}
    },
    methods:{
        chooseProperty(){
            console.log("choose property")
            if(this.isChoose){
                this.$store.dispatch("selectPropertyAction",this.property);
                 this.$store.commit("SAVE_SELECTED_PROPERTY",this.property)
                 this.$store.dispatch("formStepAction",2);

            }else{
                // go detail page
                this.$router.push({name:"PropertyDetail",params:{slug:this.property.slug}})
            }
        }
    }
}
</script>
<style scoped>
   
</style>