<template>
    <div>
      <div class="property-cards">


            <div class="card is-loading">
                <div class="image"></div>
                 <div class="property-cards-info">
                      <div class="price-location">
                          <h4></h4>
                          <p></p>
                      </div>
                    <div class="amenities"></div>
                   <div class="status-cta"></div>
           
                </div>
            </div>

</div>
    </div>
</template>
<script>
export default {
    name:"PropertyCardLoader"
}
</script>
<style lang="css" scoped>
.card{
    border:0;
}
    .card.is-loading 
  .image,
  .amenities,
  .status-cta,
  .price-location
  h4,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

.card.is-loading  .image {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.card.is-loading  h4 {
    height: 30px;
  }

.card.is-loading p {
    height: 70px;
  }
/* .card.is-loading .amenities{
    margin-top:5px;
} */
.card.is-loading .status-cta{
    height:60px;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
</style>